@import "../../styles/_variables.scss";

.projects {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  padding-left: 16.66%;
  padding-top: 8%;

  @media screen and (max-width: $break-medium) {
    padding-left: 0;
    padding-top: 120px;
  }
  @media screen and (max-width: $break-small) {
    padding-top: 140px;
  }

  &__link {
    position: relative;
    display: block;
    width: 100%;
    color: $light;
    text-decoration: none;
  }

  &__title {
    font-size: 60px;
    font-weight: 700;
    letter-spacing: -0.04em;
    line-height: 1em;
    margin-bottom: 0;
    padding-left: 2%;

    @media screen and (max-width: $break-medium) {
      font-size: 40px;
      max-width: 90%;
      display: block;
      margin: 20px auto 10px;
    }
  }

  &__paragraph {
    font-size: 16px;
    text-transform: uppercase;
    margin: 10px 0 5%;
    letter-spacing: 0.08em;
    padding-left: 2.5%;

    @media screen and (max-width: $break-medium) {
      max-width: 90%;
      display: block;
      margin: 0 auto 30px;
    }
  }

  &__images {
    position: relative;
    @media screen and (max-width: $break-medium) {
      margin-top: 40px;
    }
  }

  &__image {
    position: fixed;
    left: 0;
    top: 0;
    min-width: calc(100% + 60px);
    min-height: calc(100% + 30px);
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.4s ease-in;
    z-index: -1;
    display: block;

    @media screen and (max-width: $break-medium) {
      position: relative;
      left: auto !important;
      top: auto !important;
      width: 91%;
      min-width: auto;
      min-height: auto;
      opacity: 1 !important;
      transform: translate(0, 0);
      z-index: auto !important;
      margin: 0 auto;
    }
  }

  &__footer {
    font-size: 11px;
    color: $light;
    width: 90%;
    margin: 100px auto;
    display: none;
    @media screen and (max-width: $break-medium) {
      display: block;
    }
  }
}
