@import "../../styles/_variables.scss";

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__list {
    width: 100%;
    margin: 0;
    padding: 0 0 0 66.666%;
    @media screen and (max-width: $break-large) {
      padding: 0;
      width: auto;
    }
  }

  &__list-item {
    list-style: none;
    margin: 1.1em 0;
    font-weight: 400;
    font-size: 26px;
    @media screen and (max-width: $break-large) {
      font-size: 39px;
    }
  }

  &__link {
    text-decoration: none;
    color: $light;
    display: block;
    padding-left: 8px;
    transition: 0.2s linear;

    &:hover {
      padding-left: 12px;
      border-left: 2px solid $light;
    }
  }
}
