@import "../../styles/_variables.scss";

.lines {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: 1fr / repeat(6, 1fr);

  @media screen and (max-width: $break-medium) {
    grid-template: 1fr / repeat(3, 1fr);
  }
  @media screen and (max-width: $break-small) {
    display: none;
  }

  &__line {
    background-color: $brownLigth;
    transition: 0.2s linear;
    width: 1px;
  }
}
