.main-transition-group {
  position: relative;

  & .fade-enter {
    opacity: 0.01;
  }
  & .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  & .fade-exit {
    opacity: 1;
  }
  & .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
}

.route-section {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
