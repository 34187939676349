@import "../../styles/_variables.scss";

.navigation {
  &__button {
    position: fixed;
    right: 27px;
    top: 17px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: transparent;

    @media screen and (max-width: $break-small) {
      top: 15px;
      right: 18px;
    }

    &:hover {
      & .navigation__button-line--2 {
        width: 75%;
      }
      & .navigation__button-line--3 {
        width: 50%;
      }
    }

    &--active {
      & .navigation__button-line--1 {
        transform: rotate(45deg);
        margin: 18px 0 0;
      }
      & .navigation__button-line--2 {
        width: 100%;
        transform: rotate(135deg);
        margin: -2px 0 0;
      }
      & .navigation__button-line--3 {
        width: 0;
      }
      &:hover {
        & .navigation__button-line--2 {
          width: 100%;
        }
        & .navigation__button-line--3 {
          width: 0;
        }
      }
    }
  }

  &__button-line {
    border: none;
    width: 100%;
    height: 2px;
    background-color: $light;
    margin: 5px 0;
    transition: 0.2s linear;
  }
}
