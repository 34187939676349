@import "../../styles/_variables.scss";

.menu-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: 1fr / repeat(6, 1fr);

  @media screen and (max-width: $break-large) {
    grid-template: 1fr / repeat(3, 1fr);
  }
  @media screen and (max-width: $break-small) {
    grid-template: 1fr / 1fr;
  }

  &__item {
    background-color: $brownDark;
    width: 100%;
    border-left: 1px $brownMedium solid;
    animation: backgroundAnimation 0.3s ease-out;
  }
}

@keyframes backgroundAnimation {
  from {
    width: 1px;
  }
  to {
    width: 100%;
  }
}
