@import "../../styles/_variables.scss";

.loader {
  position: fixed;
  left: 50%;
  top: 50%;

  &__cricle {
    border-top: 2px solid $light;
    border-right: 2px solid $light;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: rotate 1s infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
