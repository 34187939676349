// @import "../../styles/_variables.scss";
// @media screen and (max-width: $break-large) {
//   grid-template: 1fr / repeat(3, 1fr);
// }

$grey: #3a3a3a;
$light: #e7dfd5;
$brownLigth: #281e1554;
$brownMedium: #281e15a6;
$brownDark: #281e15da;
$red: #fc766a;

$break-large: 1020px;
$break-medium: 760px;
$break-small: 414px;
$break-minimum: 320px;
