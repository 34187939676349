@import "../../styles/_variables.scss";

.footer {
  position: fixed;
  right: 14px;
  bottom: 5px;
  color: $light;

  @media screen and (max-width: $break-medium) {
    display: none;
  }

  &__paragraph {
    line-height: 1;
    font-size: 9px;
    letter-spacing: 0.1em;
    transform-origin: 100% 0;
    transform: rotate(270deg) translate(100%, -170%);
  }
}
