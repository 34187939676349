@import "../../styles/_variables.scss";

.background {
  position: fixed;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;

  &__image {
    position: fixed;
    display: block;
    left: 50%;
    top: 50%;
    min-width: calc(100% + 60px);
    min-height: calc(100% + 30px);
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease-in;

    @media screen and (max-width: $break-medium) {
      opacity: 0.2 !important;
      min-width: 100%;
      min-height: 100%;
      left: 0 !important;
      top: 0 !important;
      transform: translate(0, 0);
    }
  }
}
