@import "../../styles/_variables.scss";

.empty {
  position: relative;
  height: 100vh;
  padding: 180px 16.9% 300px;
  overflow-y: auto;

  @media screen and (max-width: $break-medium) {
    padding: 120px 5.5% 60px;
  }
  @media screen and (max-width: $break-small) {
    padding: 160px 5.5% 60px;
  }

  &__title {
    position: relative;
    color: $light;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 1em;
    padding-left: 12px;

    &::before {
      display: block;
      content: "";
      width: 2px;
      height: 24px;
      position: absolute;
      left: -2px;
      top: 1px;
      background-color: $red;
    }
  }

  &__paragraph {
    color: $light;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.6em;
    letter-spacing: 0.6px;

    & h3 {
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 1.9px;
      text-transform: uppercase;
      margin: 0;
      padding: 50px 0 20px;
    }

    & p {
      margin: 0;
    }

    & a {
      color: $light;
      text-decoration: none;
    }

    & ul {
      margin: 0;
      padding: 0;
    }

    & li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  &__footer {
    font-size: 11px;
    color: $light;
    margin: 100px auto;
    display: none;
    @media screen and (max-width: $break-medium) {
      display: block;
    }
  }
}
