@import "../../styles/_variables.scss";

.header {
  position: fixed;
  left: 25px;
  top: 13px;

  @media screen and (max-width: $break-small) {
    left: 15px;
  }

  &__title {
    font-size: 27px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin: 0.2em 0 0.1em;

    @media screen and (max-width: $break-medium) {
      font-size: 26px;
    }
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.02em;
  }

  &__link {
    color: $light;
    text-decoration: none;
  }
}
