@import url("https://use.typekit.net/gps5gjg.css");
@import "./_normalize.scss";
@import "./_variables.scss";

html {
  box-sizing: border-box;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  background-color: $grey;
  color: $light;
  font-family: futura-pt, futura, sans-serif;
  line-height: 1.4em;
  text-align: left;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}
