@import '../../styles/_variables.scss';

.map {
  position: absolute;
  right: 16.66%;
  top: 200px;
  width: 33.33%;
  height: 33.33vw;
  margin: 0;

  @media screen and (max-width: $break-large) {
    position: relative;
    right: auto;
    top: 50px;
    width: 100%;
  }

  & iframe {
    max-width: 100%;
    border: 0;
  }
}
