.menu-enter {
  opacity: 0.01;
}
.menu-enter.menu-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.menu-exit {
  opacity: 1;
}
.menu-exit.menu-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
