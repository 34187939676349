@import "../../styles/_variables.scss";

.home {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.home-about {
  position: fixed;
  left: 24px;
  bottom: 8px;
  @media screen and (max-width: $break-medium) {
    display: none;
  }

  &__title {
    line-height: 1.6em;
    font-size: 9px;
    font-weight: normal;
    letter-spacing: 0.2em;
  }
}

.home-slider {
  &__header {
    position: fixed;
    left: 16.66%;
    top: 50vh;
    width: 66.66%;
    transform: translateY(-55%);

    @media screen and (max-width: $break-medium) {
      left: 5%;
      width: 90%;
      filter: blur(0) !important;
    }
  }

  &__link {
    color: $light;
    text-decoration: none;
  }

  &__title {
    position: relative;
    font-size: 62px;
    font-weight: 700;
    letter-spacing: -0.04em;
    line-height: 1.1em;
    padding-left: 32px;
    animation: slider-title 6s ease infinite;
    margin: 0;

    @media screen and (max-width: $break-large) {
      font-size: 50px;
    }
    @media screen and (max-width: $break-medium) {
      font-size: 58px;
      padding-left: 5%;
    }
    @media screen and (max-width: $break-small) {
      font-size: 40px;
      max-width: 95%;
    }

    &::before {
      display: block;
      content: "";
      width: 4px;
      height: 50px;
      position: absolute;
      left: 0px;
      top: 11px;
      background-color: $red;
      @media screen and (max-width: $break-medium) {
        top: 6px;
      }
    }
  }

  &__paragraph {
    position: relative;
    font-size: 15px;
    text-transform: uppercase;
    margin-top: 4%;
    letter-spacing: 0.08em;
    padding-left: 33px;
    animation: slider-paragraph 6s ease infinite;
    max-width: 80%;

    @media screen and (max-width: $break-medium) {
      padding-left: 5%;
    }
    @media screen and (max-width: $break-small) {
      margin-top: 10%;
      font-size: 16px;
    }

    &::before {
      display: block;
      content: "";
      width: 3px;
      height: 16px;
      position: absolute;
      left: 0px;
      top: 3px;
      background-color: $light;
    }
  }

  &__image {
    position: fixed;
    left: 0;
    top: 0;
    min-width: calc(100% + 60px);
    min-height: calc(100% + 30px);
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 2.8s ease;
    z-index: -1;
  }
}

@keyframes slider-title {
  0% {
    transform: translateX(30px);
    opacity: 0;
    filter: blur(3px);
  }
  25% {
    transform: translateX(0px);
    opacity: 1;
    filter: blur(0px);
  }
  75% {
    transform: translateX(0px);
    opacity: 1;
    filter: blur(0px);
  }
  85% {
    transform: translateX(0px);
    opacity: 0;
    filter: blur(3px);
  }
  100% {
    transform: translateX(30px);
    opacity: 0;
    filter: blur(3px);
  }
}

@keyframes slider-paragraph {
  0% {
    transform: translateX(30px);
    opacity: 0;
    filter: blur(3px);
  }
  5% {
    transform: translateX(30px);
    opacity: 0;
    filter: blur(3px);
  }
  40% {
    transform: translateX(0px);
    opacity: 1;
    filter: blur(0px);
  }
  75% {
    transform: translateX(0px);
    opacity: 1;
    filter: blur(0px);
  }
  85% {
    transform: translateX(0px);
    opacity: 0;
    filter: blur(3px);
  }
  100% {
    transform: translateX(30px);
    opacity: 0;
    filter: blur(3px);
  }
}
