@import "../../styles/_variables.scss";

.gallery {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @media screen and (max-width: $break-medium) {
    position: relative;
    left: auto;
    top: auto;
  }

  &__image {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    max-height: calc(100vh - 96px);
    max-width: 66.66%;
    margin-top: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    @media screen and (max-width: $break-medium) {
      position: relative;
      left: auto !important;
      top: auto !important;
      transform: translate(0, 0);
      opacity: 1 !important;
      width: 92%;
      max-height: none;
      max-width: 100%;
      margin: 40px auto;
    }
  }

  &__label {
    position: fixed;
    left: 2%;
    bottom: 1%;
    max-width: 12%;
    text-align: left;
    @media screen and (max-width: $break-medium) {
      position: relative;
      left: auto;
      bottom: auto;
      max-width: 100%;
      width: 92%;
      margin: 150px auto 60px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 400;
    @media screen and (max-width: $break-medium) {
      font-size: 20px;
    }
  }

  &__paragraph {
    font-size: 14px;
    font-weight: 400;
    @media screen and (max-width: $break-medium) {
      font-size: 20px;
    }

    &--count {
      @media screen and (max-width: $break-medium) {
        display: none;
      }
    }
  }

  &__arrow {
    position: fixed;
    font-size: 27px;
    font-weight: 300;
    left: -100px;
    top: -100px;
    @media screen and (max-width: $break-medium) {
      display: none;
    }
  }

  &__footer {
    font-size: 16px;
    color: $light;
    width: 90%;
    margin: 100px auto;
    display: none;
    @media screen and (max-width: $break-medium) {
      display: block;
    }
  }

  &__footer-paragraph {
    &--small {
      font-size: 11px;
      margin-top: 50px;
    }
  }
}
